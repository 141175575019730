export const style = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: "80vh"
};
export const style_folder = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    // height: "80vh"
};
export const button = {
    position: 'absolute',
    right: "0",
    top: "0",
    color: "var(--text-normal)"
}